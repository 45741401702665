import React from "react";
import InfoGreen from "../../../assets/icons/infoGreen.svg";
import Tooltip from "./Tooltip";

export const ToolTipHover = ({ newValue, oldValue, date, name, quantity }) => {
  return (
    <Tooltip>
      <div className="wrraper">
        <div className="wrapper-div-patients">
          <div className="tooltip-status">
            <div>
              <img src={InfoGreen} alt="info" className="info-icon" />
            </div>
            <div className="tooltiptext-status">
              <div className="mb-2">
                <b>Changed by</b> {name}
              </div>
              <div>
                <b>Changed on</b> {date} :
              </div>

              <div>
                <b>From</b>: {oldValue}
                {oldValue === "Incomplete" ? "" : " " + quantity} to {newValue}
                {" " + quantity}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
