import React, { useEffect, useRef, useState } from "react";
import StyledAuthentification from "./StyledAuthentification";
import Modal from "react-modal";
import { ReactComponent as Warning } from "../../assets/icons/warning-red.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowNext } from "../../assets/icons/arrowNext.svg";
import { Auth } from "../../routes/Constants";
import APIVariables from "../../services/api-variables";
import LocalStorage from "../../shared/localStorage";
import {
  handleCatch,
  keepOnlyDigits,
  redirectToHomepageDependingOnUser,
  validateEmail,
} from "../../shared/helpers";
import { errorMessages } from "../../constants/errorMessages.constants";
import { registrationCategory } from "../../constants/register.constants";
import Loader from "../common/Loader/newLoader";
import Logo from "./../../assets/logo/logo_second.svg";
import PasswordExpiredModal from "./PasswordExpiredModal";
import Axios from "../../services/Axios";

import ReCAPTCHA from "react-google-recaptcha";
import MFAModal from "./MFAModal";

const Authentification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
    isLoggedIn: false,
  });
  const [maxAttemptsModal, setMaxAttemptsModal] = useState(false);

  const [message, setMessage] = useState({
    status: "",
    email: "",
    password: "",
    apiErrors: "",
  });
  
  const [errorMessage, setErrorMessage] = useState("");

  const [openModalSessionExpired, setOpenModalSessionExpired] = useState(false);
  const existSessionExpireMessage = localStorage.getItem(
    LocalStorage.EXPIRED_SESSION_MESSAGE_NHSPORTAL
  );

  const [passwordExpiredModalisOpen, setPasswordExpiredModalisOpen] =
    useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState("");

  const captchaRef = useRef(null);
  const [isCapchaNeeded, setIsCapchaNeeded] = useState(false);

  const [codeAuthentication, setCodeAuthentication] = useState({
    MAFModalIsOpen: false,
    errorMessage: "",
  });
  const [resendEmail, setResendEmail] = useState("");

  useEffect(() => {
    if (!!existSessionExpireMessage) {
      setOpenModalSessionExpired(true);
    }

    checkIsTokenNeeded();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIsTokenNeeded = async (error) => {
    const res = await Axios.get(APIVariables.USERS_LOGIN, {}).catch((error) => {
      handleCatch(error);
      setIsLoading(false);
    });

    if (res?.status === 200) {
      setIsCapchaNeeded(() => {
        setIsLoading(false);
        setErrorMessage(error);
        return res.data.require_captcha;
      });
    }
  };

  const closeSessionExpired = () => {
    setOpenModalSessionExpired(false);
    localStorage.removeItem(LocalStorage.EXPIRED_SESSION_MESSAGE_NHSPORTAL);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeCodeAuthentication = (e) => {
    const { name, value } = e.target;

    setCodeAuthentication((prevState) => ({
      ...prevState,
      ...{ [name]: keepOnlyDigits(value) },
    }));
  };
  const resendMFA = async () => {
    const res = await Axios.post(APIVariables.USER_RESEND_MFA, {
      email: loginCredentials.email,
    }).catch((error) => {
      handleCatch(error);
    });

    if (res?.status === 201 || res?.status === 200) {
      setResendEmail("Verification code resent");
      setTimeout(() => {
        setResendEmail("");
      }, 10000);
    }
  };
  const handleConfirmModal = () => {
    if (
      !codeAuthentication.mfa_code ||
      codeAuthentication.mfa_code.length !== 6
    ) {
      setCodeAuthentication((prevState) => ({
        ...prevState,
        ...{ errorMessage: "Invalid MFA code." },
      }));
    } else {
      setCodeAuthentication((prevState) => ({
        ...prevState,
        ...{
          mfa_code: codeAuthentication.mfa_code,
        },
      }));
      onSubmit({ preventDefault: () => {} });
    }
  };

  const checkInputs = async (email, password) => {
    var error = false;
    var errors = {
      status: "",
      email: "",
      password: "",
      apiErrors: "",
    };

    if (!validateEmail(email)) {
      errors.email = errorMessages.INVALID_EMAIL;
      error = true;
    }

    if (password.length === 0) {
      errors.password = errorMessages.INVALID_PASSWORD;
      error = true;
    }

    if (error) errors.status = "error";
    setMessage(errors);

    return errors;
  };
  const releaseNotesAddIntoLocalStorage = async (data) => {
    const token = "token " + data.access_token;

    const res = await Axios.get(APIVariables.RELEASE_NOTES_LIST, {
      headers: {
        Authorization: token,
      },
    }).catch((error) => {
      handleCatch(error);
    });

    if (res?.status === 200) {
      localStorage.setItem(
        LocalStorage.ARE_NEW_RELEASE_NOTES,
        JSON.stringify(res.data)
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    const token = captchaRef?.current?.getValue();
    captchaRef.current?.reset();

    const data = {
      email: loginCredentials.email,
      password: loginCredentials.password,
      ...(isCapchaNeeded && { token }),
      mfa_code: codeAuthentication?.mfa_code,
    };

    setIsCapchaNeeded(false);

    const errors = await checkInputs(data.email, data.password);

    if (errors.status !== "error") {
      try {
        const getData = await Axios.post(APIVariables.USERS_LOGIN, {
          ...data,
        });

        if (!!getData?.data?.require_mfa) {
          setCodeAuthentication((prevState) => ({
            ...prevState,
            ...{ MAFModalIsOpen: true },
          }));
          setIsLoading(false);
        } else {
          const BE_TOKEN = getData?.data?.access_token;
          let token = "token " + BE_TOKEN;

          submitLoginCall(getData, BE_TOKEN, token);
        }
      } catch (error) {
        const errors = error?.response?.data;
        let errorToSend;
        if (
          (errors?.non_field_errors && errors?.non_field_errors[0])?.includes(
            "Invalid MFA code."
          )
        ) {
          setCodeAuthentication((prevState) => ({
            ...prevState,
            ...{ errorMessage: "Invalid MFA code." },
          }));
        } else {
          setCodeAuthentication((prevState) => ({
            ...prevState,
            ...{
              MAFModalIsOpen: false,
            },
          }));
        }

        if (typeof errors === "string") {
          errorToSend = [errorMessages.INTERNAL_SERVER_ERROR];
        } else {
          if (
            (errors?.non_field_errors && errors?.non_field_errors[0])?.includes(
              "Max login attempts reached."
            )
          ) {
            setMaxAttemptsModal(true);
          }
          if (errors?.password_expired?.includes("True")) {
            setPasswordExpiredModalisOpen(true);
            setResetPasswordLink(errors.password_reset_url[0]);
            return;
          }
          const errorsArray = [];
          Object?.keys(errors).forEach((key) => {
            errorsArray.push(errors[key]);
          });
          errorToSend = errorsArray;
        }

        checkIsTokenNeeded(errorToSend);
      }
    } else {
      setIsLoading(false);
    }
  };

  const submitLoginCall = async (getData, BE_TOKEN, token) => {
    if (getData?.data?.display_release_notes === true) {
      releaseNotesAddIntoLocalStorage(getData?.data);
    }

    if (BE_TOKEN) {
      const res = await Axios.get(APIVariables.USERS_PROFILE, {
        headers: {
          Authorization: token,
        },
      }).catch((error) => {
        handleCatch(error);
      });
      const user = {
        isLoggedIn: true,
        token,
        ...res.data,
        ...(res.data.user_type === registrationCategory.PRESCRIBER && {
          ...res.data?.prescriber,
        }),
        ...(res.data.user_type === registrationCategory.PHARMACY && {
          ...res.data?.connected_nurse?.prescriber,
        }),
        ...(res.data.user_type === registrationCategory.CONNECTED_NURSE && {
          ...res.data?.pharmacy,
        }),
      };

      localStorage.setItem(LocalStorage.USER_NHSPORTAL, JSON.stringify(user));
      const nextRoute = localStorage.getItem(LocalStorage.NEXT_PAGE_NHSPORTAL);
      if (nextRoute) {
        window.location.href = nextRoute;
        localStorage.removeItem(LocalStorage.NEXT_PAGE_NHSPORTAL);
      } else {
        // REGISTRATION ACTIVE
        if (getData.data) {
          let route = redirectToHomepageDependingOnUser(res.data.user_type);
          window.location.href = route;
        }
      }
      setIsLoading(false);
    }
  };

  const continueModalQuit = () => {
    setPasswordExpiredModalisOpen(false);
    setResetPasswordLink("");

    window.location.href = resetPasswordLink;
  };

  return (
    <StyledAuthentification>
      <div className="wrapper-login">
        <div className="wrapper-auth">
          <div className="wrapper-title">
            <div className="text font-size-30 bold">
              <img src={Logo} alt="NHS Logo" />
            </div>
          </div>
          <form className="mt-4" onSubmit={onSubmit}>
            <div>
              <label>Email Address:</label>
              <input
                type="email"
                onChange={handleChange}
                name="email"
                className={message.email.length > 0 ? "error-select" : ""}
                value={loginCredentials?.email || ""}
              />
              <span className="required-text-reset">{message.email ?? ""}</span>
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                onChange={handleChange}
                name="password"
                className={message.password.length > 0 ? "error-select" : ""}
                value={loginCredentials?.password || ""}
              />
              {isCapchaNeeded && (
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                />
              )}

              <span className="required-text-reset mb-2">
                {message.password ?? ""}
              </span>

              <a href={Auth.RESET_PASSWORD}>Forgot password?</a>
              <br />
              <span className="required-text-reset">
                {message.apiErrors ?? ""}
              </span>
              {(errorMessage || []).map((item, index) => (
                <span className="required-text-reset" key={index}>
                  {item[0] !== "Invalid MFA code." && item}
                </span>
              ))}
            </div>

            <button
              className="primary-button custom-login-button"
              disabled={isLoading}
            >
              Login
              {isLoading && <Loader />}
            </button>
          </form>
        </div>
        <hr />
        <a className="wrapper-register" href={Auth.REGISTER}>
          <div className="font-size-18">Register for an account</div>
          <ArrowNext />
        </a>
      </div>
      <Modal
        isOpen={maxAttemptsModal}
        onRequestClose={() => setMaxAttemptsModal(false)}
        style={customStyles}
        contentLabel="Error Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div className="main-text-error">Account Locked</div>
          <div className="second-text">
            You have had 3 incorrect password attempts. Your password is now
            locked. Please return to the login screen and reset your password.
          </div>
        </div>
        <div className="wrapper-buttons">
          <button
            className="red-button"
            onClick={() => navigate(Auth.RESET_PASSWORD)}
          >
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={openModalSessionExpired}
        style={customStyles}
        contentLabel="Session Expired Modal"
        overlayClassName="overlay-modal"
        shouldCloseOnOverlayClick={false} // add this to prevent outside click to prevent modal close
      >
        <div className="wrapper-modal">
          <div className="image">
            <Warning />
          </div>
          <div className="main-text-error">Session Expired</div>
          <div className="second-text">{existSessionExpireMessage}</div>
        </div>
        <div className="wrapper-buttons">
          <button className="red-button" onClick={closeSessionExpired}>
            Login
          </button>
        </div>
      </Modal>

      <PasswordExpiredModal
        passwordExpiredModalisOpen={passwordExpiredModalisOpen}
        continueModalQuit={continueModalQuit}
      />

      <MFAModal
        resendEmail={resendEmail}
        resendMFA={resendMFA}
        MAFModalIsOpen={codeAuthentication.MAFModalIsOpen}
        confirmMFAModal={handleConfirmModal}
        handleChangeCodeAuthentication={handleChangeCodeAuthentication}
        errorMessage={codeAuthentication.errorMessage}
        mfa_code={codeAuthentication.mfa_code}
      />
    </StyledAuthentification>
  );
};
export default Authentification;

let customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 515,
    width: "34%",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 20%)",
    borderRadius: 30,
  },
};
