import BrochureLink from "./BrochureLink";

const BrochureWithText = ({
  type,
  patientType,
  hcp,
  scheduleDisplayType,
  typeOfPAF,
  registerPatientType,
  maxCapsulesOrWeeks,
  confirmationRiskLength,
}) => {
  const selectedRegisterPatientType =
    registerPatientType || maxCapsulesOrWeeks?.patient?.type;

  const returnPatientType =
    typeOfPAF?.WOMANOFCP === selectedRegisterPatientType
      ? "WCBP"
      : typeOfPAF?.MALE === selectedRegisterPatientType
      ? "MALE"
      : "WNCBP";

  const returnPatientTypeLongVersion =
    typeOfPAF?.WOMANOFCP === selectedRegisterPatientType ||
    typeOfPAF?.WOMANOFCP === maxCapsulesOrWeeks?.patient?.type
      ? "woman of childbearing potential"
      : typeOfPAF?.MALE === selectedRegisterPatientType ||
        typeOfPAF?.MALE === maxCapsulesOrWeeks?.patient?.type
      ? "male"
      : "woman of non-childbearing potential";

  switch (type) {
    case "CONFIRMATION_NOT_PROVIDED_DESCRIPTION":
      return (
        <div>
          Please check the confirmation{" "}
          {confirmationRiskLength > 1 ? "box(es)" : "box"} that the patient has
          been counselled on the terms of the Pregnancy Prevention Programme.
          Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on pregnancy prevention measures.
        </div>
      );
    case "NEED_VALID_NEGATIVE_TEST":
      return (
        <div>
          A valid negative pregnancy test must have been conducted a maximum of
          3 days prior to the prescription date. Please refer to the SmPC or{" "}
          <BrochureLink hcp={hcp} /> for further information on pregnancy
          prevention measures.
        </div>
      );
    case "CONTINUE_OFF_LABEL":
      return (
        <div>
          You have selected an indication that is not officially approved for
          use with {hcp.name}. Are you sure you want to proceed?
        </div>
      );
    case "NEED_VALID_NEGATIVE_TEST_INCOMPLETE":
      return (
        <div>
          A valid negative pregnancy test must have been conducted a maximum of
          3 days prior to the prescription date. Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} /> for further information on pregnancy
          prevention measures.
        </div>
      );
    case "CONTINUE_INDICATION_INCOMPLETE":
      return (
        <div>
          You failed to insert a valid indication. If an indication is not
          available, Please click continue or please go back and select a valid
          indication.Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on licenced indications and off-label use.
        </div>
      );
    case "NEED_VALID_DATE_BIRTH":
      return (
        <div>
          You have entered an age that is under 18 years old who is not
          officially approved for treatment with {hcp.name}. Are you sure you
          want to proceed?
        </div>
      );
    case "WEEKS_EXCEEDED_WCBP":
      return (
        <div>
          A capsule quantity has been entered in that greater than{" "}
          {typeOfPAF?.WOMANOFCP === selectedRegisterPatientType
            ? maxCapsulesOrWeeks.max_capsules_wcbp
            : maxCapsulesOrWeeks.max_capsules_m_or_wncbp}{" "}
          capsules
          {`, exceeding a ${
            typeOfPAF?.WOMANOFCP === selectedRegisterPatientType
              ? maxCapsulesOrWeeks.max_weeks_wcbp
              : maxCapsulesOrWeeks.max_weeks_m_or_wncbp
          } week supply for ${returnPatientType}. `}
          Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on pregnancy prevention measures.
        </div>
      );
    case "WEEKS_EXCEEDED":
      return (
        <div>
          A Prescription Authorisation Form for a {returnPatientTypeLongVersion}{" "}
          may not exceed more than{" "}
          {typeOfPAF?.WOMANOFCP ===
          (maxCapsulesOrWeeks?.patient?.type || registerPatientType)
            ? maxCapsulesOrWeeks.max_weeks_wcbp
            : maxCapsulesOrWeeks.max_weeks_m_or_wncbp}{" "}
          weeks. Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on pregnancy prevention measures.
        </div>
      );
    case "CYCLES_EXCEEDED_WCBP":
      return (
        <div>
          A Prescription Authorisation Form for a woman of childbearing
          potential may not exceed more than 1 cycle. Please refer to the SmPC
          and <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on pregnancy prevention measures.
        </div>
      );
    case "NON_COMPLIANT_PAF":
      return (
        <div>
          This PAF contains incomplete/non-compliant data, please seek
          confirmation of the pregnancy prevention measures from the patient or
          the prescriber and input/correct the necessary fields before
          approving. Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on pregnancy prevention measures.
        </div>
      );
    case "NON_COMPLIANT_PAF_SCREEN_3":
      return (
        <div>
          I have read and understood the{" "}
          <BrochureLink hcp={hcp} patientType={patientType} />. I confirm that
          this {hcp.name} Prescription Authorisation Form has not been completed
          fully or contains non-compliant data however I wish to proceed to
          submission based on clinical judgement.
        </div>
      );

    case "CONTINUE_CAPSULE_PER_CYCLE_INCOMPLETE":
      return (
        <div>
          You failed to insert any capsules per {scheduleDisplayType}. If the
          number of capsules is not available, Please click continue or please
          go back and select valid capsules per {scheduleDisplayType}. Please
          refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on capsules per {scheduleDisplayType}.
        </div>
      );

    case "CONTINUE_NUMBER_OF_CYCLES_INCOMPLETE":
      return (
        <div>
          You failed to insert any Number of Cycles. If the number of capsules
          is not available, Please click continue or please go back and select
          the number of cycles. Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on the number of cycles allowed for each patient category.
        </div>
      );
    case "CONTINUE_NUMBER_OF_WEEKS_INCOMPLETE":
      return (
        <div>
          You failed to insert any Number of Weeks. If the number of capsules is
          not available, Please click continue or please go back and select the
          number of weeks. Please refer to the SmPC and{" "}
          <BrochureLink hcp={hcp} patientType={patientType} /> for further
          information on the number of weeks allowed for each patient category.
        </div>
      );

    case "PLEASE_CONFIRM_APPROVAL":
      return (
        <div>
          I have read and understood the{" "}
          <BrochureLink hcp={hcp} patientType={patientType} />. I confirm the
          information provided on this PAF is accurate, complete and in
          accordance with the requirements of the pregnancy prevention programme
          for {hcp.name}.
          {patientType === "Male" ? (
            <div className="mt-4">
              For male patients; (1) The patient has been counselled/reminded
              about teratogenic risk and the requirement to use a condom if
              sexually active with a pregnant woman or a woman of childbearing
              potential not using effective contraception. (2) The supply of
              treatment is no more than 12 weeks.
            </div>
          ) : patientType === "Woman of Childbearing Potential" ? (
            <div className="mt-4">
              For a woman of childbearing potential (WCBP); (1) The WCBP has
              been counselled/reminded about teratogenic risk and has been on at
              least one effective method of contraception for at least 4 weeks.
              (2) The WCBP has had a negative pregnancy test within the 3 days
              before the prescription date. (3) The dispensing of {hcp.name}
              is within 7 days of the prescription date. (4) The supply of
              treatment is no more than 4 weeks.
            </div>
          ) : (
            <div className="mt-4">
              For women of non-childbearing potential the supply of treatment is
              no more than 12 weeks.
            </div>
          )}{" "}
        </div>
      );

    case "CHANGE_OF_MOLECULE":
      return (
        <div>
          You have selected a molecule that is different from what was previous
          prescribed, if you want to continue, please confirm that the TIF has
          been downloaded and completed by the Patient
        </div>
      );
    default:
      return null;
  }
};
export default BrochureWithText;
