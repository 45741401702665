const APIVariables = {
  // Dropdowns
  HCP_HOSPITALS: "/hcp/hospitals/list",
  HCP_PHARMACY_INSTITUTIONS: "/hcp/pharmacy/institutions",
  HCP_CONNECTED_NURSES: "/hcp/connected/nurses",
  HCP_CONNECTED_NURSE: "/hcp/connected/nurses/",
  HCP_CONNECTED_NURSES_DELETE: "/hcp/connected/nurses/",
  HCP_CONNECTED_NURSES_EDIT: "/hcp/connected/nurses/edit/",

  //MAHS,
  MAHS: "/orders/mah/list",

  //DISPENSE
  DISPENSER_TYPE: "/nominate-dispenser",
  DISPENSER_TYPE_LOCATIONS: "nominated-dispensers/",
  APPROVE_AND_NOMINATE: "/approve-and-nominate",
  APPROVE_AND_DISPENSE: "/approve-and-dispense",
  DISPENSE: "/dispense",

  //HOMECARE
  HCP_HOMECARES: "/hcp/pharmacy/connected-homecare",
  HCP_HOMECARE: "/hcp/connected-homecare/",
  HCP_HOMECARES_LIST: "/hcp/connected-homecare",
  HCP_HOMECARE_DELETE: "/hcp/connected-homecare/",
  USERS_SIGNUP_HCP_HOMECARE: "/hcp/connected-homecare",

  //CONECTED-PHARM
  HCP_CON_PHARMACISTS: "/hcp/connected-pharmacist/list",
  HCP_CON_PHARMACIST_DELETE: "/hcp/connected-inpatient/",
  HCP_CON_PHARMACIST_ADD: "/hcp/connected-pharmacist/add",
  HCP_CON_PHARMACIST: "/hcp/connected-pharmacist/",

  //OUTPATIENTS
  HCP_OUTPATIENTS: "/hcp/pharmacy/connected-outpatient",
  HCP_OUTPATIENT: "/hcp/connected-outpatient/",
  HCP_OUTPATIENT_DELETE: "/hcp/connected-outpatient/",
  HCP_OUTPATIENTS_ALL: "/hcp/connected-outpatient",
  USERS_SIGNUP_HCP_OUTPATIENT: "/hcp/connected-outpatient",

  // Authentication
  USERS_SIGNUP_VALIDATE_PHARMACY: "/users/signup/pharmacy/validate/",
  USERS_SIGNUP_VALIDATE_PRESCRIBER: "/users/signup/prescriber/validate/",
  USERS_SIGNUP_PHARMACY: "/users/signup/pharmacy/",
  USERS_SIGNUP_PRESCRIBER: "/users/signup/prescriber/",
  USERS_ACTIVATE: "/users/activate",
  USERS_PASSWORD_RESET: "/users/password-reset/",
  USERS_PASSWORD_RESET_GET_EMAIL: "/users/password-reset/resolve/",
  USERS_LOGIN: "/users/login",
  USERS_LOGOUT: "/users/logout",
  USERS_PROFILE: "/users/profile",
  USERS_PROFILE_EDIT: "/users/edit",
  USER_RESEND_MFA: "/users/resend-mfa",

  PATIENT_STATISTICS: "/patients/statistics",
  PATIENTS_BROCHURES: "/patients/brochures",
  PATIENTS_BROCHURES_DOWNLOAD: "/patients/brochures/download/",
  PATIENTS_DETAILS: "/patients/",
  PATIENTS_LIST: "/patients/list",
  PATIENTS_ADVERSE_EVENTS: "/patients/adverse-events",
  PATIENTS_ADVERSE_EVENTS_DOWNLOAD: "/patients/adverse-events/",
  ORDERS_MAH_REPORT_ADVERSE_EVENTS: "/orders/mah/report-adverse-event/data",
  ORDERS_WHOLESALERS: "/orders/wholesalers",
  PRESCRIPTION_PATIENT_TIF: "/prescriptions/patient-tif/",

  USERS_SIGNUP_CONNECTED_NURSES: "/users/signup/connected-nurse/",
  USERS_SIGNUP_PATIENT_MALE: "/users/signup/patient/male/",
  USERS_SIGNUP_PATIENT_WOMAN_NCP: "/users/signup/patient/woman-ncp/",
  USERS_SIGNUP_PATIENT_WOMAN_CP: "/users/signup/patient/woman-cp/",

  USERS_SIGNUP_PATIENTS_MALE: "/users/signup/patient/male/",
  USERS_SIGNUP_PATIENTS_WOMAN_CP: "/users/signup/patient/woman-cp/",

  USERS_PASSWORD_VALIDATE: "/users/password/validate",

  USERS_PHARMACY_REGISTRATION: "/users/pharmacy/registration/",
  USERS_PRESCRIBER_REGISTRATION: "/users/prescriber/registration/",
  USERS_CONNECTED_NURSE_REGISTRATION: "/users/connected-nurse/registration/",

  PRESCRIPTIONS_FILTERS: "/prescriptions/filters",
  PRESCRIPTIONS_NOTIFICATIONS: "/prescriptions/notifications",
  PRESCRIPTIONS_NOTIFICATIONS_COUNT: "/users/notifications/count",
  ADMIN_NOTIFICATIONS: "/users/general-notifications",

  PRESCRIPTIONS_NOTIFICATIONS_READ: "/prescriptions/notifications/read/",
  PRESCRIPTIONS_ADMIN_NOTIFICATIONS_READ: "/users/general-notifications/read/",

  ADMIN_NOTIFICATIONS_READ: "/users/general-notifications/read/",
  HCP_BROCHURE_INFO: "/prescriptions/hcp-brochure/",

  //PRESCRIPTIONS
  PRESCRIPTIONS: "/prescriptions/",
  // PRESCRIPTIONS_PATIENTS: "/prescriptions/patients/",
  PRESCRIPTIONS_RENEW: "/renew",
  PRESCRIPTIONS_ACCEPT: "/prescriptions/accept/",
  PRESCRIPTIONS_REJECT: "/prescriptions/reject/",
  PRESCRIPTIONS_REVERT: "/prescriptions/revert/",
  PRESCRIPTIONS_GET: "/prescriptions/",
  PRESCRIPTIONS_CREATE_POST: "/prescriptions/create",
  PRESCRIPTIONS_TIF: "/prescriptions/tifs/",

  // PHARMACY
  PHARMACY_LIST: "/hcp/customer-solution/pharmacies",
  PHARMACY_ID: "/hcp/pharmacy/details/",

  // DOWNLOAD PDFs
  DOWNLOAD_TIF: "/patients/tif/download/",
  DOWNLOAD_PAF: "/patients/paf/download/",

  // RELEASE NOTES
  RELEASE_NOTES_LIST: "/release-notes/list",
  RELEASE_NOTES_READ: "/release-notes/read",

  // PRODUCTS
  GET_PRODUCTS: "/settings/products",
  SEND_ID_OF_PRODUCTS: "/settings/confirmations",
  PRESCRIPTION_FIELDS: "/prescriptions/fields/",
  // GET TEXT FOR BANNER
  GET_BANNER_TEXT: "/users/login-notes",

  // test

  GETCONF: "/settings/confirmations",

  // REGISTER PRODUCTS FROM PROFILE
  REGISTER_PRODUCT: "/users/register-product",
};

export default APIVariables;
