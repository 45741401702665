import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import styled from "styled-components";

import Colors from "../../../shared/colors";
import {
  handleCatch,
  isDispensingMoreThan3DaysBetween2Dates,
  isDispensingMoreThan7DaysBetween2Dates,
  prescriberName,
} from "../../../shared/helpers";
import moment from "moment";
import CalendarComponent from "../Calendar/Calendar";
import { ReactComponent as DropdownArrowDown } from "../../../assets/icons/dropdownArrowDown.svg";
import { ReactComponent as DropdownArrowUp } from "../../../assets/icons/dropdownArrowUp.svg";
import useRoles from "../../../hooks/useRoles";
import APIVariables from "../../../services/api-variables";
import Axios from "../../../services/Axios";
import { ToolTipHover } from "./ToolTipHover";
import { typeOfPAF } from "../../../constants/typeOfPAF";

const Layout = styled.div`
  background-color: white;
  padding: 10px;
  .error {
    color: ${Colors.valencia}!important;
  }
  .patients-wrapper-container {
    .row {
      padding: 0px 0px 20px 0px !important;
    }
    .col {
      padding-left: 0;
      padding-right: 0;
    }

    label {
      color: ${Colors.orient};
    }
  }
  .row-other {
    margin-left: 10px;
    margin-top: 5px;
  }
  .table-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  table {
    width: 80%;
  }

  td,
  th {
    border: 1px solid ${Colors.alto};
    text-align: center;
    padding: 8px;
    font-weight: normal;

    @media screen and (max-width: 850px) {
      padding: 6px;
    }
  }

  td:first-child,
  th:first-child {
    text-align: left;
    color: ${Colors.orient};
  }

  .container {
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }

  .edit-option {
    margin-left: 10px;
    color: #e41818;
    text-decoration: underline;
    cursor: pointer;
  }
  .custom-select__control {
    width: 75%;
  }

  .custom-select__menu {
    width: 75%;
  }
  .selectWidth {
    width: 95%;
  }

  .mt-2 {
    input {
      margin-top: 10px;
      margin-bottom: 15px;
      background-color: #f8f8f8;
      color: ${Colors.doveGray} !important;
      border: 0;
      border-bottom: 1px solid ${Colors.silver};
      font-size: 20px;
      line-height: 22px;
      height: 30px;
    }
  }

  .tool-tip-wrapper-capsule-strength {
    min-width: 44px;
  }
  .tool-tip-wrapper-capsule-per-cycle {
    min-width: 85px;
  }
  .display-flex-tooltip {
    display: flex;
  }
  .padding-right {
    padding-right: 10px;
  }
`;

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <DropdownArrowUp />
        ) : (
          <DropdownArrowDown />
        )}
      </components.DropdownIndicator>
    )
  );
};

const ViewPAF = ({
  receivedForm,
  handleChangeSubmitedForm,
  isFormApproved,
  isCreateNewPAF,
  handleAditionalOptionsChange,
  aditionalOptionsState,
  dispensingForm,
  deletePropertyValue,
  setCapsule_changes_tracker,
  capsule_changes_tracker,
  prescriptionField,
  updateChildObject,
  hideEditButton,
  checkedItems,
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    !!updateChildObject && updateChildObject(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const isRenewal = window.location.pathname.includes("renew");

  const typeOfForRenew =
    window.location.pathname.split("/")[3] === "womanOfCPRenew2"
      ? "womanOfCP"
      : window.location.pathname.split("/")[3];

  const [isLastPregancyTestActive, setIsLastPregancyTestActive] =
    useState(false);
  const [isPrescribedOn, setIsPrescribedOn] = useState(false);
  const { isPharmacy, isPrescriber, isConnectedNurse, storageData } =
    useRoles();
  const [initialConfirmation, setInitialConfirmation] = useState([]);

  const is_form_ready_form_approve =
    isPharmacy &&
    (form?.status === "Submitted" ||
      form?.status === "Overdue" ||
      form?.status === "Expiring");

  const displayWeeks = form?.schedule_display_type === "weeks";

  const isPatientInitiationOrRenewPAF = useMemo(() => {
    return (
      window.location.pathname.includes("patient-initiation") ||
      window.location.pathname.includes("renew")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // check if new-capsules exists , if not, initialize these values
  const newCapsuleExist =
    Array.isArray(form?.strengths) &&
    form?.strengths.map((strength) => strength?.new_capsules);

  useEffect(() => {
    if (form?.strengths && !isFormApproved) {
      const updatedForm = {
        ...form,
        strengths:
          !!form &&
          form?.strengths?.map((strength) => ({
            ...strength,
            new_capsules: null,
            new_strength_id: null,
            new_capsules_other: null,
            is_new_other_capsules: false,
          })),
      };
      setForm(updatedForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!newCapsuleExist]);

  useEffect(() => {
    if (!isCreateNewPAF && isPharmacy && is_form_ready_form_approve) {
      const strengths = form?.strengths;

      const history = {
        old_preganacy_test: receivedForm.pregnancy_test,
        old_n_cycles: receivedForm.n_cycles,
        strengths,
      };
      setCapsule_changes_tracker(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_form_ready_form_approve]);

  useEffect(() => {
    setForm({ ...receivedForm });
    // setEditCapsule({});
    setIsLastPregancyTestActive(false);
    setIsPrescribedOn(false);
  }, [receivedForm]);

  const editField = (_, fieldName) => {
    if (fieldName === "lastNegativePregnanacyTest") {
      setIsLastPregancyTestActive(true);
    }

    if (fieldName === "prescribedOn") {
      setIsPrescribedOn(true);
    }
  };

  const disableAditionalOptions = () => {
    return (
      form?.status === "Approved" ||
      form?.status === "Rejected" ||
      form?.status === "Dispensed" ||
      !!isFormApproved ||
      !isPharmacy
    );
  };

  const extractIndication = () => {
    if (typeof form.indication === "string") {
      if (form?.indication?.includes("Other")) {
        return `Other (${form?.indication_other})`;
      } else if (form?.indication?.includes("Clinical trial")) {
        return `Clinical Trial (${form?.clinical_trial})`;
      } else {
        return form?.indication;
      }
    }

    if (typeof form.indication === "object") {
      if (form.indication?.label?.includes("Other")) {
        return `Other (${form?.indication_other})`;
      } else if (form?.indication?.label?.includes("Clinical trial")) {
        return `Clinical Trial (${form?.clinical_trial})`;
      } else {
        return form?.indication?.label;
      }
    }
  };

  const setTotalCapsules = (form) => {
    if (!!form?.strengths) {
      // Calculate the sum of the values using the reduce method
      const capsuleValues = form?.strengths?.map((element) => {
        if (
          !!element?.new_capsules &&
          typeof element.new_capsules !== "string"
        ) {
          return parseFloat(element?.new_capsules);
        } else if (!!element?.new_capsules_other) {
          return element?.new_capsules_other;
        } else {
          return element.capsules;
        }
      });
      const sumOfValues = capsuleValues.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);

      const numberOfCyles = !!form?.new_capsule_cycles
        ? form?.new_capsule_cycles?.id
        : parseFloat(form?.n_cycles);

      const numberOfWeeks =
        !!form?.new_capsule_cycles?.id && form?.new_capsule_cycles?.id !== 0
          ? form?.new_capsule_cycles?.id
          : !!form?.n_weeks_other
          ? form?.n_weeks_other
          : parseFloat(form?.n_weeks);

      const totalCapsules =
        +sumOfValues * (displayWeeks ? numberOfWeeks / 4 : numberOfCyles || 0);

      setForm((prevState) => ({
        ...prevState,
        capsule_strength: sumOfValues,
        new_totalCapsules: isNaN(totalCapsules) ? 0 : Math.ceil(totalCapsules),
        total_capsules: isNaN(totalCapsules) ? 0 : Math.ceil(totalCapsules),
      }));
    }
  };

  const [prescriptionForFields, setPrescriptionForFields] = useState([]);
  // Get confirmations product from BE
  const getPrescription = async () => {
    if (isPharmacy) {
      const data = await Axios.get(
        APIVariables.PRESCRIPTION_FIELDS +
          (!!form?.product_id && form?.product_id) +
          `/${form?.patient?.type}`
      ).catch((error) => {
        handleCatch(error);
      });
      setPrescriptionForFields(data.data);
    }
  };

  useEffect(() => {
    !isCreateNewPAF && !!form?.product_id && getPrescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.product_id]);

  // if you select one option , is removed from dropdown

  const strengthValueToExclude = !isCreateNewPAF
    ? (form?.strengths || []).map((strength) =>
        !!strength.strength_value ? strength.strength_value : undefined
      )
    : [];
  const modifiedCapsuleStrengths = (prescriptionForFields?.strengths || [])
    ?.filter(
      (strength) =>
        !strengthValueToExclude?.includes(parseFloat(strength.value))
    )
    ?.map((strength) => {
      return {
        id: strength.id,
        label: strength.value,
      };
    });

  const CapsulesperCycle = prescriptionForFields?.capsule_cycle
    ?.filter((strength) => strength.id !== -1 && strength.value !== null)
    .map((strength) => {
      return {
        id: strength.id,
        label: strength.value,
      };
    });

  const modifiedNCycles = prescriptionForFields?.n_cycles?.map((strength) => {
    return {
      id: strength.id,
      label: strength.value,
    };
  });

  const modifiedWeeksCycles = prescriptionForFields?.n_weeks?.map(
    (strength) => {
      return {
        id: strength.id,
        label: strength.value,
      };
    }
  );

  const oldValueType =
    displayWeeks && form?.history
      ? form?.history?.n_weeks === null || form?.history?.n_weeks === 0
        ? form?.history?.n_weeks_other
        : form?.history?.n_weeks
      : form?.history?.n_cycles;

  const newStrengths = Array.isArray(form?.strengths)
    ? form?.strengths?.map((strength) => ({
        id: strength.strength_id,
        label: strength.strength_value + "mg",
      }))
    : null;

  const newCapsules = Array.isArray(form?.strengths)
    ? form?.strengths.map((capsule) => ({
        value: capsule?.new_capsules
          ? capsule?.new_capsules
          : capsule?.capsules,
        label: `${
          capsule.new_capsules ? capsule.new_capsules : capsule.capsules
        } Capsules`,
      }))
    : null;

  const handlePrescriptionFields = (selectedOption, value, name) => {
    if (value === "incomplete_strengths") {
      const numericPart = parseFloat(selectedOption.label);

      // Create a new strength object
      const newStrength = {
        strength_value: numericPart,
        strength_id: "Incomplete",
        new_strength_id: selectedOption?.id,
        capsules: "Incomplete",
      };

      // Clone the existing strengths array (if it exists)
      const incompleteStrengths = Array.isArray(form?.strengths)
        ? [...form.strengths]
        : [];

      // Push the new strength object into the array
      incompleteStrengths.push(newStrength);

      setForm({ ...form, strengths: incompleteStrengths });
    }

    if (name === "capsule_strength") {
      const formStrengths = form?.strengths;
      // Create a copy of the original strengths to store in new_capsuleStrength
      const new_capsuleStrength = [...formStrengths];

      const indexToUpdate = new_capsuleStrength?.findIndex(
        (strength) => strength.strength_id === value?.strength_id
      );

      const numericPart = parseFloat(selectedOption.label);

      // Check if an object with matching strength_id was found
      if (indexToUpdate !== -1) {
        // Update the strength_value in the formStrengths array
        const updatedFormStrengths = [...formStrengths]; // Create a copy
        // updatedFormStrengths[indexToUpdate].old_strength_value=
        if (
          updatedFormStrengths[indexToUpdate].old_strenght_Value === undefined
        ) {
          updatedFormStrengths[indexToUpdate].old_strenght_Value =
            new_capsuleStrength[indexToUpdate].strength_value;
        }
        updatedFormStrengths[indexToUpdate].strength_value = numericPart; // Update the strength_value

        // Create a new parameter 'new_strength_data' to store the updated id
        new_capsuleStrength[indexToUpdate].new_strength_id = selectedOption.id;
        // Update the form state with the new formStrengths
        handleAditionalOptionsChange(
          !!selectedOption,
          "select",
          "capsuleStrength"
        );

        // I checked if strengths are the same with new strength and keep only strengths because it's not a change

        form?.strengths?.forEach((item) => {
          if (item.strength_id === item.new_strength_id) {
            item.new_strength_id = null;
          }
        });

        setForm({ ...form, strengths: updatedFormStrengths });
        setTotalCapsules({ ...form, strengths: updatedFormStrengths });
      }
    }

    if (value === "capsule_cycles") {
      const updateCapsuleCycles = { ...form };
      updateCapsuleCycles.new_capsule_cycles = selectedOption;
      updateCapsuleCycles.n_weeks_other = selectedOption?.id === 0 ? 1 : null;
      updateCapsuleCycles.n_weeks = selectedOption?.id === 0 && 0;
      updateCapsuleCycles.new_totalCapsules =
        +form?.total_capsules * parseFloat(selectedOption.label);

      handleAditionalOptionsChange(selectedOption.id, "select", "n_cycles");
      setForm(updateCapsuleCycles);
      setTotalCapsules(updateCapsuleCycles);
    }

    if (value === "number_weeks_other") {
      let selectedValue = parseInt(selectedOption, 10);
      if (isNaN(selectedValue) || selectedValue < 1) {
        selectedValue = 1;
      } else if (selectedValue > 12) {
        selectedValue = 12;
      }

      const updateCapsuleWeeks = { ...form };

      updateCapsuleWeeks.n_weeks_other = selectedValue;

      updateCapsuleWeeks.n_weeks = 0;
      updateCapsuleWeeks.new_totalCapsules =
        (+form?.capsule_strength * selectedValue) / 4;

      setForm(updateCapsuleWeeks);
      handleAditionalOptionsChange(selectedValue.id, "select", "n_weeks");
      setTotalCapsules(updateCapsuleWeeks);
    }

    if (name === "capsules" || value === "capsules") {
      const matchingObject = form?.strengths?.find(
        (obj) => obj.strength_id === value?.strength_id
      );

      let parsedValue = parseInt(selectedOption);
      selectedOption = parsedValue ? parsedValue.toString() : selectedOption;
      if (+selectedOption <= 0) {
        selectedOption = 1;
      } else if (+selectedOption > 200) {
        selectedOption = "200";
      }

      if (name >= 0 && name < form?.strengths?.length) {
        // Update the new_capsules property at the specified index
        form.strengths[name].new_capsules_other = parseFloat(selectedOption);
        form.strengths[name].is_new_other_capsules = true;
      }

      if (matchingObject) {
        matchingObject.new_capsules =
          selectedOption.id === 0
            ? selectedOption.label
            : parseFloat(selectedOption.label);
        matchingObject.new_capsules_other = null;
        matchingObject.is_new_other_capsules = false;
      }
      handleAditionalOptionsChange(selectedOption, "select", "capsulePerCycle");
      if (
        !!matchingObject &&
        matchingObject?.new_capsules === "Other (please state)" &&
        matchingObject?.new_capsules_other === null
      ) {
        matchingObject.new_capsules_other = 1;
        matchingObject.is_new_other_capsules = true;
      }

      setForm({ ...form });
      setTotalCapsules({ ...form });
    }
  };

  const placeholder = "Select"; // Replace with your desired placeholder value
  const populatedRename = Array.from(
    { length: form?.strengths?.length },
    () => placeholder
  );

  !!CapsulesperCycle &&
    CapsulesperCycle?.forEach((item) => {
      const index = prescriptionField?.strengths?.findIndex(
        (element) => element.id === item.id
      );
      if (index !== -1) {
        populatedRename[index] = item + 1;
      }
    });

  const other_capsules_value =
    !isCreateNewPAF && form?.strengths?.map((other_value) => other_value);

  const [editingState, setEditingState] = useState({
    strengths: false,
    capsules: false,
    n_cycles: false,
  });

  const [editing2State, setEditing2State] = useState({
    capsules: Array(form?.strengths?.length).fill(false),
    strengths: Array(form?.strengths?.length).fill(false),
  });

  const handleEdit = (index, type) => {
    const newEditing2States = { ...editing2State };
    newEditing2States[type][index] = true;
    setEditing2State(newEditing2States);
  };

  const displayConditionallyApprovedBy = () => {
    if (
      form?.status === "Approved" ||
      form?.status === "Rejected" ||
      form?.status === "Dispensed" ||
      form?.status === "Overdue"
    ) {
      return form?.approved_or_rejected_by;
    }

    if (
      form?.status === "Submitted" &&
      (isFormApproved === null || isFormApproved === undefined)
    ) {
      return "Awaiting approval";
    }

    if (
      form?.status === "Submitted" &&
      (isFormApproved !== null || isFormApproved !== undefined)
    ) {
      return storageData?.first_name + " " + storageData?.last_name;
    }
    return "Awaiting approval";
  };

  const displayConditionallyApprovedOrRejectedLabel = () => {
    if (form?.status === "Approved") {
      return "Approved By:";
    }

    if (form?.status === "Rejected") {
      return "Rejected By:";
    }

    if (form?.status === "Submitted" && isFormApproved === null) {
      return "Approved By:";
    }

    if (form?.status === "Submitted" && isFormApproved === true) {
      return "Approved By:";
    }

    if (form?.status === "Submitted" && isFormApproved === false) {
      return "Rejected By:";
    }

    return "Approved By:";
  };

  const displayConditionallyPrescriberName = () => {
    if (isCreateNewPAF) return prescriberName();
    if (
      form?.prescriber &&
      form?.prescriber?.first_name &&
      form?.prescriber?.last_name
    )
      return form?.prescriber?.first_name + " " + form?.prescriber?.last_name;
    return "";
  };

  const getPharmacyFromLocalStorage = () => {
    return storageData?.hospital?.value + ", " + storageData?.hospital?.address;
  };

  function typeOfForm() {
    const getTypeFromURL = window.location.pathname.split("/")[3];

    if (getTypeFromURL === "womanOfNCP") {
      return "Woman of Non-Childbearing Potential";
    } else if (getTypeFromURL === "male") {
      return "Male";
    } else {
      return "Woman of Childbearing Potential";
    }
  }

  const getPatientCategory = () => {
    // To check patient_type --> type ?
    if (!!form?.patient?.patient_type) {
      return form?.patient?.patient_type;
    } else if (!!form?.patient_type) {
      // Used for Print TIF
      return form.patient_type;
    } else if (!!form?.type) {
      switch (form?.type) {
        case "male":
          return "Male";
        case "womanOfNCP":
          return "Woman of Non-Childbearing Potential";
        case "womanOfCP":
          return "Woman of Childbearing Potential";
        default:
          return;
      }
    } else {
      // Used for Renew PAF
      return typeOfForm();
    }
  };

  const displayLastNegativepregnanacyDate = () => {
    if (!!form?.pregnancy_test) {
      return moment(form?.pregnancy_test).format("DD / MM / YYYY");
    } else if (!!form?.patient?.pregnancy_test) {
      return moment(form?.patient?.pregnancy_test).format("DD / MM / YYYY");
    } else {
      moment().format("DD / MM / YYYY");
    }
  };

  const markInRedPrescribedOnField = (form) => {
    const typeOfFormVar = getPatientCategory();

    if (typeOfFormVar !== "Woman of Childbearing Potential") {
      return false;
    }

    if (form?.status === "Approved") {
      return isDispensingMoreThan7DaysBetween2Dates(
        form?.pfa_issued,
        form?.accepted_date
      );
    } else if (form?.status === "Rejected") {
      return isDispensingMoreThan7DaysBetween2Dates(
        form?.pfa_issued,
        form?.rejected_date
      );
    } else {
      const isCreate = window.location.pathname.includes("patient-initiation");
      const isRenewal = window.location.pathname.includes("renew");

      if (isCreate || isRenewal) {
        return false;
      } else {
        return isDispensingMoreThan7DaysBetween2Dates(
          form?.pfa_issued,
          new Date()
        );
      }
    }
  };

  const markInRedLastNegativePreganacyTest = (form) => {
    if (isCreateNewPAF) {
      return isDispensingMoreThan3DaysBetween2Dates(
        new Date(),
        form?.pregnancy_test
      );
    } else {
      return isDispensingMoreThan3DaysBetween2Dates(
        form?.pfa_issued,
        form?.pregnancy_test
      );
    }
  };

  const deleteProperty = (propName) => {
    deletePropertyValue(propName);
  };

  const checkIfNumberOfCyclesExceeded = (form) => {
    const typeOfFormVar = !!form?.type ? form?.type : form?.patient?.type;

    if (typeOfFormVar === typeOfPAF.WOMANOFCP) {
      const nrCycles = !!form.new_capsule_cycles
        ? form.new_capsule_cycles.id
        : !!form?.number_cycles?.id
        ? form?.number_cycles?.id
        : parseFloat(form?.n_cycles);

      return nrCycles > 1;
    }
    return false;
  };

  const checkIfNumberOfWeeksExceeded = (form) => {
    const typeOfFormVar = isRenewal
      ? typeOfForRenew
      : !!form?.type
      ? form?.type
      : form?.patient?.type;

    const isWOMANOFCP = typeOfFormVar === typeOfPAF.WOMANOFCP;
    const isMALE = typeOfFormVar === typeOfPAF.MALE;
    const isWOMANOFNCP = typeOfFormVar === typeOfPAF.WOMANOFNCP;

    const maxWeeksWCBP =
      prescriptionField?.max_weeks_wcbp || form?.max_weeks_wcbp;
    const maxWeeksMOrWNCBP =
      prescriptionField?.max_weeks_m_or_wncbp || form?.max_weeks_m_or_wncbp;

    const nrCycles = !!form?.new_capsule_cycles?.id
      ? form.new_capsule_cycles.id
      : !!form?.number_weeks?.id
      ? form?.number_weeks?.id
      : !!form?.n_weeks_other
      ? form?.n_weeks_other
      : !!form?.other
      ? parseFloat(form?.other)
      : parseFloat(form?.n_weeks);

    return isWOMANOFCP
      ? maxWeeksWCBP && maxWeeksWCBP > 0
        ? nrCycles > maxWeeksWCBP
        : false
      : isMALE || isWOMANOFNCP
      ? maxWeeksMOrWNCBP && maxWeeksMOrWNCBP > 0
        ? nrCycles > maxWeeksMOrWNCBP
        : false
      : false;
  };

  const checkIfTotalCapsulesExceeded = (form) => {
    const typeOfFormVar = isRenewal
      ? typeOfForRenew
      : !!form?.type
      ? form?.type
      : form?.patient?.type;

    if (
      form?.schedule_display_type === "weeks" ||
      prescriptionField?.schedule_display_type === "weeks"
    ) {
      const totalCapsules = !!form?.new_totalCapsules
        ? form?.new_totalCapsules
        : form?.total_capsules;

      const maxCapsulesWcbp =
        form?.max_capsules_wcbp || prescriptionField?.max_capsules_wcbp;

      const maxCapsulesMorWncbp =
        form?.max_capsules_m_or_wncbp ||
        prescriptionField?.max_capsules_m_or_wncbp;

      return typeOfFormVar === typeOfPAF.WOMANOFCP
        ? maxCapsulesWcbp > 0 && totalCapsules > maxCapsulesWcbp
        : maxCapsulesMorWncbp > 0 && totalCapsules > maxCapsulesMorWncbp;
    }
  };

  // Used at Create PAF
  const handedHospitalName =
    storageData?.hospital?.value + ", " + storageData?.hospital?.address;

  const is_preganacy_test_changes = !!capsule_changes_tracker?.pregnancy_test;

  const capsuleRenderer = (form, element, key) => {
    let result;

    if (isRenewal) {
      result = "Incomplete";
    }
    if (!isRenewal) {
      if (element.new_capsules === "Other (please state)") {
        result = element.new_capsules_other + " Capsules";
      } else if (element.new_capsules) {
        result = element.new_capsules + " Capsules";
      } else if (element.capsules !== "Incomplete") {
        result = element.capsules + " Capsules";
      } else {
        result = "Incomplete";
      }
    }
    const labelClass = result === "Incomplete" ? "red-label" : "";
    const greenText =
      !isRenewal &&
      form?.history?.capsules_ammends &&
      strengthValueSort[key]?.new_capsules
        ? "green-haze-color"
        : "";
    return (
      <span className={`${labelClass} ${greenText} font-size-16`}>
        {result}
      </span>
    );
  };

  const nCyclesRenderer = (form) => {
    let label;
    if (!!form?.number_cycles?.label) {
      label = form?.number_cycles.label;
    } else if (!!form?.new_capsule_cycles) {
      label = form?.new_capsule_cycles.label;
    } else {
      label = form?.n_cycles || "Incomplete";
    }
    const labelClass =
      label === "Incomplete" || checkIfNumberOfCyclesExceeded(form) === true
        ? "red-label"
        : "";
    const greenText = !!form.new_capsule_cycles
      ? form.new_capsule_cycles
      : !!form?.n_cycles && !!form?.history?.n_cycles
      ? "green-haze-color"
      : "";

    return (
      <span className={`${labelClass}  ${greenText} font-size-16`}>
        {label}
      </span>
    );
  };
  const nWeeksRenderer = (form) => {
    let label;
    if (
      !!form?.new_capsule_cycles &&
      form?.new_capsule_cycles?.label !== "Other (Please State)"
    ) {
      label = form?.new_capsule_cycles?.label;
    } else if (!!form?.other) {
      label = form?.other + " Weeks";
    } else if (!!form?.number_weeks?.id) {
      label = form?.number_weeks?.id + " Weeks";
    } else if (!!form?.n_weeks_other) {
      label = form?.n_weeks_other + " Weeks";
    } else {
      label = form?.n_weeks || "Incomplete";
    }

    const greenText = !!form.new_capsule_cycles
      ? form.new_capsule_cycles
      : !!(form?.history?.n_weeks === 0
          ? form?.history?.n_weeks_other
          : form?.history?.n_weeks)
      ? "green-haze-color"
      : "";

    const labelClass =
      label === "Incomplete" || checkIfNumberOfWeeksExceeded(form) === true
        ? "red-label"
        : "";

    return (
      <span className={`${labelClass}  ${greenText} font-size-16`}>
        {label}
      </span>
    );
  };

  const capsuleQuantityRendererAtCreate = (
    correspondingStrength,
    form,
    element
  ) => {
    let result = "-";
    let totalResult = "-";

    if (
      correspondingStrength &&
      (prescriptionField?.schedule_display_type === "cycles" ||
        form?.schedule_display_type === "cycles")
    ) {
      if (
        element.label &&
        (form?.new_capsule_cycles?.id || form?.number_cycles?.id)
      ) {
        result =
          element.label *
          (form?.new_capsule_cycles?.id || form?.number_cycles?.id);
      }
      if (element.label === 0) {
        if (
          element.other &&
          (form?.new_capsule_cycles?.id || form?.number_cycles?.id)
        ) {
          result =
            element.other *
            (form?.new_capsule_cycles?.id || form?.number_cycles?.id);
        }
      }
      return result;
    }

    if (
      correspondingStrength &&
      (prescriptionField?.schedule_display_type === "weeks" || displayWeeks)
    ) {
      if (
        element.label &&
        (form?.new_capsule_cycles?.id || form?.number_weeks?.id || form?.other)
      ) {
        result =
          element.label *
          (form?.new_capsule_cycles?.id ||
            form?.number_weeks?.id ||
            form?.other);
        totalResult = Math.ceil(result / 4);
      }
      if (element.label === 0) {
        if (
          element.other &&
          (form?.new_capsule_cycles?.id ||
            form?.number_weeks?.id ||
            form?.other)
        ) {
          result =
            element.other *
            (form?.new_capsule_cycles?.id ||
              form?.number_weeks?.id ||
              form?.other);
          totalResult = Math.ceil(result / 4);
        }
      }
      return totalResult;
    }
  };
  const capsuleQuantityRenderer = (form, element) => {
    let result;

    if (
      (element.new_capsules_other ||
        element.new_capsules ||
        element.capsules) &&
      (displayWeeks
        ? form?.new_capsule_cycles?.id ||
          parseFloat(form?.n_weeks_other) ||
          parseFloat(form?.n_weeks)
        : form?.new_capsule_cycles?.id || parseFloat(form?.n_cycles))
    ) {
      if (element.new_capsules_other) {
        result =
          element.new_capsules_other *
          (displayWeeks
            ? form?.new_capsule_cycles?.id ||
              parseFloat(form?.n_weeks_other) ||
              parseFloat(form?.n_weeks)
            : form?.new_capsule_cycles?.id || parseFloat(form?.n_cycles));
      } else if (element.new_capsules) {
        result =
          element.new_capsules *
          (displayWeeks
            ? form?.new_capsule_cycles?.id ||
              parseFloat(form?.n_weeks_other) ||
              parseFloat(form?.n_weeks)
            : form?.new_capsule_cycles?.id || parseFloat(form?.n_cycles));
      } else {
        result =
          element.capsules *
          (displayWeeks
            ? form?.new_capsule_cycles?.id ||
              parseFloat(form?.n_weeks_other) ||
              parseFloat(form?.n_weeks)
            : form?.new_capsule_cycles?.id || parseFloat(form?.n_cycles));
      }
    } else {
      result = "-";
    }

    return displayWeeks
      ? !isNaN(Math.ceil(result / 4)) && Math.ceil(result / 4)
      : !isNaN(result) && result;
  };

  // Sort value from capsule tracker to display dates correct
  const strengthValueSort = form?.history?.capsule_changes_tracker
    ?.slice()
    .sort(
      (a, b) => parseFloat(a?.strength_value) - parseFloat(b?.strength_value)
    );

  const handleChange = (event, index) => {
    if (
      (form?.status === "Submitted" ||
        form?.status === "Overdue" ||
        form?.status === "Expiring") &&
      isPharmacy
    ) {
      handleChangeSubmitedForm("confirmation", event, index);
    }
  };

  // Set up the initial form array
  useEffect(() => {
    if (form && Array.isArray(form?.prescription_confirmation_risks)) {
      const copiedArray = [...form?.prescription_confirmation_risks];
      setInitialConfirmation(copiedArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!form?.prescription_confirmation_risks]);

  return form ? (
    <Layout>
      <h2 className="mineShaft-color">Patient Details</h2>
      <div className="container patients-wrapper-container">
        <div className="row">
          <div className="col">
            <label>Patient Category:</label>
            <div className="font-size-16">{getPatientCategory()}</div>
          </div>
          <div className="col" />
        </div>
        <div className="row">
          <div className="col">
            <label>Indication:</label>
            <div
              className={`font-size-16 ${
                extractIndication()?.toLowerCase()?.includes("other") &&
                (isPrescriber || isConnectedNurse)
                  ? "red-label"
                  : ""
              }`}
            >
              {extractIndication() || (
                <div className="red-label font-size-16">Incomplete</div>
              )}
            </div>
          </div>
          <div className="col">
            <label>Molecule:</label>
            <div className="font-size-16">
              {form?.molecule?.label
                ? form.molecule?.label
                : form?.drug ?? form?.product}
            </div>
          </div>
          {getPatientCategory() === "Woman of Childbearing Potential" && (
            <div className="col">
              <label>Last Negative Pregnancy Test:</label>
              <div
                className={
                  "font-size-16 display-flex-tooltip " +
                  (markInRedLastNegativePreganacyTest(form)
                    ? "red-label"
                    : "") +
                  (!isCreateNewPAF &&
                  !is_form_ready_form_approve &&
                  is_preganacy_test_changes
                    ? " green-haze"
                    : "")
                }
              >
                {displayLastNegativepregnanacyDate() || (
                  <span className="red-label font-size-16">Incomplete</span>
                )}
                {!isCreateNewPAF && !!form?.history?.pregnancy_test && (
                  <ToolTipHover
                    quantity={""}
                    name={form?.history?.pharmacy_user}
                    date={moment(form?.history?.ammend_date).format(
                      "DD / MM / YYYY"
                    )}
                    oldValue={moment(form?.history?.pregnancy_test).format(
                      "DD / MM / YYYY"
                    )}
                    newValue={moment(form?.pregnancy_test).format(
                      "DD / MM / YYYY"
                    )}
                  />
                )}

                {!!hideEditButton && isFormApproved === null && (
                  <React.Fragment>
                    <span
                      className="edit-option"
                      onClick={(event) =>
                        editField(event, "lastNegativePregnanacyTest")
                      }
                    >
                      EDIT
                    </span>
                  </React.Fragment>
                )}
              </div>
              {(form?.status === "Submitted" ||
                form?.status === "Overdue" ||
                form?.status === "Expiring") &&
                isLastPregancyTestActive === true && (
                  <CalendarComponent
                    close={setIsLastPregancyTestActive}
                    onClose={() => setIsLastPregancyTestActive(false)}
                    selectRange={false}
                    maxDate={new Date()}
                    setDateRaw={(date) => {
                      handleChangeSubmitedForm("pregnancy_test", date);
                    }}
                    date={
                      form?.patient?.pregnancy_test || form?.pregnancy_test
                        ? moment(
                            form?.patient?.pregnancy_test ||
                              form?.pregnancy_test
                          ).toDate()
                        : moment(new Date()).toDate()
                    }
                    form={form}
                    onResetCalendar={() => {
                      deleteProperty("pregnancy_test");
                    }}
                  />
                )}
            </div>
          )}
          <div className="col">
            <label>Date of PAF:</label>
            {isPrescribedOn === false && (
              <div
                className={
                  "font-size-16 " +
                  (markInRedPrescribedOnField(form) ? "red-label" : "")
                }
              >
                {isCreateNewPAF
                  ? moment(new Date()).format("DD / MM / YYYY")
                  : moment(form?.pfa_issued).format("DD / MM / YYYY")}{" "}
              </div>
            )}
            {form?.status === "Submitted" && isPrescribedOn === true && (
              <CalendarComponent
                close={setIsPrescribedOn}
                selectRange={false}
                maxDate={new Date()}
                setDateRaw={(date) => {
                  handleChangeSubmitedForm("prescribed_on", date);
                }}
                date={new Date(form?.pfa_issued)}
              />
            )}
          </div>

          {getPatientCategory() !== "Woman of Childbearing Potential" && (
            <div className="col" />
          )}
        </div>
      </div>

      <div>
        {prescriptionField?.prescription_confirmation_risks?.map((item) => (
          <div key={item.id} className="mt-2 mb-2">
            <label
              className={
                "custom-checkbox font-size-16 dove-gray" +
                (checkedItems[`risk-checkbox-${item.id}`] === false
                  ? " red-label"
                  : "")
              }
              htmlFor={`risk-checkbox-${item.id}`}
            >
              {item.confirmation_text}
              <input
                type="checkbox"
                id="confirmation"
                name="confirmation"
                checked={checkedItems[`risk-checkbox-${item.id}`] || false}
                onChange={
                  (form?.status === "Submitted" ||
                    form?.status === "Overdue" ||
                    form?.status === "Expiring") &&
                  isPharmacy
                    ? (event) => handleChangeSubmitedForm("confirmation", event)
                    : () => true
                }
              />
              <span className="checkmark mt-0" />
            </label>
          </div>
        ))}
      </div>
      <div>
        {form?.prescription_confirmation_risks?.map((item, index) => (
          <div key={index} className="mt-2 mb-2">
            <label
              className={
                "custom-checkbox font-size-16 dove-gray" +
                (item.confirmation_risk_patient === false ? " red-label" : "") +
                (item.confirmation_checked_by_pharmacist === true
                  ? " green-haze"
                  : "")
              }
              htmlFor={`confirmation-${index}`}
            >
              {item.confirmation_text}{" "}
              <input
                type="checkbox"
                id={`confirmation-${index}`}
                name={`confirmation-${index}`}
                checked={item.confirmation_risk_patient}
                onChange={(event) => handleChange(event, index)}
                disabled={
                  isPharmacy && isFormApproved !== null
                    ? true
                    : initialConfirmation[index]?.confirmation_risk_patient
                }
              />
              <span className="checkmark mt-0" />
            </label>
          </div>
        ))}
      </div>

      <hr className="gray-lightE8-hr" />

      <div className="container patients-wrapper-container mb-2">
        <div className="row">
          <div className="col">
            <label
              className={
                aditionalOptionsState?.showErrors &&
                !aditionalOptionsState?.validation?.capsuleStrength?.isValid
                  ? "error"
                  : ""
              }
            >
              {disableAditionalOptions()
                ? "Capsule Strength:"
                : "Capsule Strength*:"}
            </label>
          </div>
          <div className="col">
            <label
              className={`${
                aditionalOptionsState?.showErrors &&
                !aditionalOptionsState?.validation?.capsulePerCycle?.isValid
                  ? "error"
                  : ""
              }padding-right`}
            >
              {prescriptionField?.schedule_display_type === "weeks" ||
              displayWeeks
                ? `Number of capsules per 4 week supply:${
                    !disableAditionalOptions() ? "*" : ""
                  }`
                : disableAditionalOptions()
                ? "Capsules per Cycle:"
                : "Capsules per Cycle*:"}
            </label>
          </div>
          <div className="col">
            <label
              className={
                aditionalOptionsState?.showErrors &&
                !aditionalOptionsState?.validation?.numberOfCycles?.isValid
                  ? "error"
                  : ""
              }
            >
              {prescriptionField?.schedule_display_type === "weeks" ||
              displayWeeks
                ? `Number of supply weeks:${
                    !disableAditionalOptions() ? "*" : ""
                  }`
                : disableAditionalOptions()
                ? "Number of Cycles:"
                : "Number of Cycles*:"}
            </label>
          </div>
          <div className="col">
            <label>
              {prescriptionField?.schedule_display_type === "weeks" ||
              displayWeeks
                ? "Total capsules supplied:"
                : "Total Capsules:"}
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {!form?.capsules ? (
              !!form?.strengths && form?.strengths?.length > 0 ? (
                form?.strengths.map((element, index) => (
                  <div className="row" key={element.id}>
                    {editing2State.strengths[index] ? (
                      <div>
                        <Select
                          menuShouldScrollIntoView={false}
                          name="capsuleStrengths"
                          placeholder="Select"
                          classNamePrefix="margin-custom-select custom-select"
                          components={{
                            DropdownIndicator: DropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(selectedOption) => {
                            handlePrescriptionFields(
                              selectedOption,
                              element,
                              "capsule_strength"
                            );
                            const newEditingStates = [
                              ...editing2State.strengths,
                            ];
                            newEditingStates[index] = false;
                            setEditing2State({
                              ...editing2State,
                              strengths: newEditingStates,
                            });
                          }}
                          value={newStrengths[index]}
                          options={modifiedCapsuleStrengths}
                        />
                      </div>
                    ) : (
                      <>
                        <span className="display-flex-tooltip font-size-16">
                          {!isRenewal && !form?.capsules ? (
                            <span
                              className={
                                !isRenewal &&
                                form?.history?.strength_ammends &&
                                strengthValueSort[index]?.new_strength_id
                                  ? "green-haze-color font-size-16"
                                  : "font-size-16"
                              }
                            >
                              {element?.strength_value} mg{" "}
                            </span>
                          ) : (
                            index === 0 && (
                              <span className="red-label font-size-16">
                                Incomplete{" "}
                              </span>
                            )
                          )}

                          {!isRenewal &&
                            form?.history?.strength_ammends &&
                            strengthValueSort[index]?.new_strength_id && (
                              <ToolTipHover
                                quantity={" mg"}
                                name={form?.history?.pharmacy_user}
                                date={moment(form?.history?.ammend_date).format(
                                  "DD / MM / YYYY"
                                )}
                                oldValue={
                                  strengthValueSort[index]?.old_strenght_Value
                                    ? strengthValueSort[index]
                                        ?.old_strenght_Value
                                    : strengthValueSort[index]?.strength_id
                                }
                                newValue={
                                  strengthValueSort[index]?.strength_value
                                }
                              />
                            )}
                          {!!hideEditButton && isFormApproved === null && (
                            <span
                              className="edit-option"
                              onClick={() => handleEdit(index, "strengths")}
                            >
                              EDIT
                            </span>
                          )}
                        </span>
                        {!!element?.new_capsules &&
                          !isFormApproved &&
                          element?.new_capsules === "Other (please state)" && (
                            <div
                              className="mb-5"
                              style={{ paddingBottom: "50px" }}
                            ></div>
                          )}
                      </>
                    )}
                  </div>
                ))
              ) : !editingState.strengths ? (
                <>
                  {!!form?.strengths?.strength_value ? (
                    form?.strengths?.strength_value + " mg"
                  ) : (
                    <span className="red-label font-size-16">Incomplete </span>
                  )}

                  {!!hideEditButton && isFormApproved === null && (
                    <span
                      className="edit-option"
                      onClick={() =>
                        setEditingState({
                          ...editingState,
                          strengths: true,
                        })
                      }
                    >
                      EDIT
                    </span>
                  )}
                </>
              ) : (
                <>
                  <Select
                    menuShouldScrollIntoView={false}
                    name="capsuleStrengths"
                    placeholder="Select"
                    classNamePrefix="margin-custom-select custom-select"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(selectedOption) => {
                      handlePrescriptionFields(
                        selectedOption,
                        "incomplete_strengths"
                      );
                      setEditingState({
                        ...editingState,
                        strengths: false,
                      });
                    }}
                    value={newStrengths}
                    options={modifiedCapsuleStrengths}
                  />
                </>
              )
            ) : !!form?.capsules && form?.capsules.length === 0 ? (
              <span className="red-label font-size-16">Incomplete </span>
            ) : (
              form.capsules
                ?.slice()
                .sort(
                  (a, b) =>
                    parseFloat(a?.capsuleStrength?.value) -
                    parseFloat(b?.capsuleStrength?.value)
                )
                .map((element, key) => (
                  <div className="row" key={key}>
                    <span className="font-size-16">
                      {element?.capsuleStrength?.value}
                    </span>
                  </div>
                ))
            )}
          </div>

          <div className="col">
            {form?.strengths?.length === 0 ||
            !form?.strengths ||
            (isRenewal && !form.capsules) ? (
              <span className="red-label font-size-16"> Incomplete</span>
            ) : !form.capsules ? (
              !!form?.strengths &&
              form?.strengths?.map((element, key, index) => (
                <div className="row" key={key}>
                  {editing2State.capsules[key] ? (
                    <div>
                      <Select
                        menuShouldScrollIntoView={false}
                        placeholder="Select"
                        classNamePrefix="margin-custom-select custom-select"
                        components={{
                          DropdownIndicator: DropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(selectedOption) => {
                          handlePrescriptionFields(
                            selectedOption,
                            element,
                            "capsules"
                          );
                          const newEditingStates = [...editing2State.capsules];
                          newEditingStates[key] = false;
                          setEditing2State({
                            ...editing2State,
                            capsules: newEditingStates,
                          });
                        }}
                        value={newCapsules}
                        options={CapsulesperCycle}
                      />
                    </div>
                  ) : (
                    <span className="display-flex-tooltip">
                      {capsuleRenderer(form, element, key)}
                      {!isRenewal &&
                        form?.history?.capsules_ammends &&
                        strengthValueSort[key]?.new_capsules && (
                          <ToolTipHover
                            quantity={" Capsules"}
                            name={form?.history?.pharmacy_user}
                            date={moment(form?.history?.ammend_date).format(
                              "DD / MM / YYYY"
                            )}
                            oldValue={
                              strengthValueSort[key]?.capsules !== null
                                ? strengthValueSort[key]?.capsules
                                : "Incomplete"
                            }
                            newValue={
                              strengthValueSort[key].is_new_other_capsules
                                ? strengthValueSort[key]?.new_capsules_other
                                : strengthValueSort[key]?.new_capsules
                            }
                          />
                        )}
                      {!!hideEditButton && isFormApproved === null && (
                        <span
                          className="edit-option"
                          onClick={() => handleEdit(key, "capsules")}
                        >
                          EDIT
                        </span>
                      )}
                    </span>
                  )}

                  {!!element?.new_capsules &&
                    !isFormApproved &&
                    element?.new_capsules === "Other (please state)" && (
                      <div className="row row-other mb-3">
                        <div className="col">
                          <label>
                            Other Capsules per{" "}
                            {displayWeeks ? "4 Weeks:*" : "Cycle:*"}
                          </label>
                          <input
                            type="number"
                            onChange={(e) => {
                              // Handle the change for "Other Capsules per Cycle" here
                              handlePrescriptionFields(
                                e.target.value,
                                "capsules",
                                key // Pass the key/index of the element to identify it
                              );
                            }}
                            value={
                              other_capsules_value[key]?.new_capsules_other ===
                              null
                                ? 1
                                : other_capsules_value[key]?.new_capsules_other
                            }
                            name={`capsule_cycle_other_${element.id}`}
                            placeholder="Other Capsules per Cycle"
                            min={1}
                          />
                        </div>
                      </div>
                    )}
                </div>
              ))
            ) : !!form?.capsules && form?.capsules.length === 0 ? (
              <span className="red-label font-size-16">Incomplete </span>
            ) : (
              form.capsules
                ?.slice()
                .sort(
                  (a, b) =>
                    parseFloat(a?.capsuleStrength?.value) -
                    parseFloat(b?.capsuleStrength?.value)
                )
                ?.map((element, key) => (
                  <div className="row" key={key}>
                    <span className="font-size-16">
                      {element?.label === 0 ? element?.other : element?.label}{" "}
                      Capsules
                    </span>
                  </div>
                ))
            )}
          </div>
          <div className="col">
            {!editingState.n_cycles ? (
              <div className="display-flex-tooltip padding-15">
                <span className="display-flex-tooltip">
                  {prescriptionField?.schedule_display_type === "weeks" ||
                  displayWeeks ||
                  form?.number_weeks?.label?.includes("Weeks")
                    ? nWeeksRenderer(form)
                    : nCyclesRenderer(form)}
                  {(!!form?.n_cycles || !!form?.n_weeks) &&
                    (!!form?.history?.n_cycles ||
                      !!form?.history?.n_weeks ||
                      !!form?.history?.n_weeks_other) && (
                      <ToolTipHover
                        quantity={form?.schedule_display_type}
                        name={form?.history?.pharmacy_user}
                        date={moment(form?.history?.ammend_date).format(
                          "DD / MM / YYYY"
                        )}
                        oldValue={oldValueType || "Incomplete "}
                        newValue={
                          displayWeeks
                            ? parseFloat(form?.n_weeks) ||
                              parseFloat(form?.n_weeks_other)
                            : parseFloat(form?.n_cycles)
                        }
                      />
                    )}
                </span>

                {!!hideEditButton && isFormApproved === null && (
                  <span
                    className="edit-option"
                    onClick={() =>
                      setEditingState({
                        ...editingState,
                        n_cycles: true,
                      })
                    }
                  >
                    EDIT
                  </span>
                )}
              </div>
            ) : (
              <Select
                menuShouldScrollIntoView={false}
                placeholder="Select"
                classNamePrefix="margin-custom-select custom-select"
                components={{
                  DropdownIndicator: DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                onChange={(selectedOption) => {
                  handlePrescriptionFields(selectedOption, "capsule_cycles");
                  setEditingState({
                    ...editingState,
                    n_cycles: false,
                  }); // Hide the Select after making a selection
                }}
                value={form?.new_capsule_cycles}
                options={displayWeeks ? modifiedWeeksCycles : modifiedNCycles}
              />
            )}
            {form?.new_capsule_cycles &&
              !isFormApproved &&
              form?.new_capsule_cycles.label === "Other (Please State)" && (
                <div className="row mb-3">
                  <div className="col row-other">
                    <label>Other Weeks:*</label>
                    <input
                      type="number"
                      name={`capsule_cycle_other_$`}
                      value={
                        !!form?.n_weeks_other ? form?.n_weeks_other : 1 || ""
                      }
                      onChange={(e) => {
                        handlePrescriptionFields(
                          e.target.value,
                          "number_weeks_other"
                        );
                      }}
                      placeholder="Other Weeks"
                      min={1}
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="col padding-15">
            <span
              className={`font-size-16 ${
                checkIfTotalCapsulesExceeded(form) && "red-label"
              }  ${hideEditButton && "align-self"}`}
            >
              {!!form?.new_totalCapsules
                ? form?.new_totalCapsules
                : form?.total_capsules}{" "}
              Capsules
            </span>
          </div>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Capsule Strength</th>
              {!!form?.capsules
                ? form?.capsules
                    ?.slice() // Create a copy of the array to avoid modifying the original
                    .sort((a, b) => {
                      // Extract and parse the numeric value from capsuleStrength.value
                      const aValue = parseFloat(a?.capsuleStrength?.value) || 0;
                      const bValue = parseFloat(b?.capsuleStrength?.value) || 0;

                      // Sort in ascending order based on the numeric value
                      return aValue - bValue;
                    })
                    .map((element, key) => (
                      <th>{element?.capsuleStrength?.value}</th>
                    ))
                : !!form?.strengths &&
                  !isRenewal &&
                  form?.strengths.map((element) => (
                    <th key={element.strength_id}>
                      {element.strength_value} mg
                    </th>
                  ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Capsule Quantity</td>
              {!!form.capsules
                ? form?.capsules
                    ?.slice() // Create a copy of the array to avoid modifying the original
                    .sort((a, b) => {
                      // Extract and parse the numeric value from capsuleStrength.value
                      const aValue = parseFloat(a?.capsuleStrength?.value) || 0;
                      const bValue = parseFloat(b?.capsuleStrength?.value) || 0;

                      // Sort in ascending order based on the numeric value
                      return aValue - bValue;
                    })
                    .map((element) => {
                      // Find the corresponding element in prescriptionField?.strengths based on id
                      const correspondingStrength =
                        prescriptionField === undefined
                          ? form
                          : prescriptionField?.strengths?.find(
                              (strength) => strength.id === element.id
                            );

                      // Render the span based on whether correspondingStrength is found
                      return (
                        <td key={element.id}>
                          {capsuleQuantityRendererAtCreate(
                            correspondingStrength,
                            form,
                            element
                          )}

                          {correspondingStrength && correspondingStrength.label}
                        </td>
                      );
                    })
                : !!form?.strengths &&
                  !isRenewal &&
                  form?.strengths?.map((element) => (
                    <td>{capsuleQuantityRenderer(form, element)}</td>
                  ))}
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="gray-lightE8-hr" />

      <div className="container patients-wrapper-container">
        <div className="row">
          <div className="col">
            <label>Prescriber:</label>
            <div className="font-size-16">
              {displayConditionallyPrescriberName()}
            </div>
          </div>
          <div className="col">
            <label>Hospital Address:</label>
            <div className="font-size-16">
              {form?.hospital_name_and_address ||
                form?.patient?.hospital ||
                handedHospitalName}
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col">
            <label>{displayConditionallyApprovedOrRejectedLabel()}</label>
            <div className="font-size-16">
              {displayConditionallyApprovedBy()}
            </div>
          </div>
          <div className="col">
            <label>Pharmacy Address:</label>
            <div className="font-size-16">
              {dispensingForm?.location?.address ||
                form?.pharmacy_address ||
                form?.patient?.pharmacy ||
                getPharmacyFromLocalStorage()}
            </div>
          </div>
          <div className="col"></div>
        </div>

        {form.status !== "Rejected" ? (
          <>
            {!isPatientInitiationOrRenewPAF && (
              <div className="row">
                <div className="col">
                  <label>Brand selected:</label>
                  <div className="font-size-16">
                    {form?.marketing_holder || "Awaiting to be Dispensed"}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div />
        )}
      </div>
    </Layout>
  ) : (
    ""
  );
};

ViewPAF.propTypes = {
  receivedForm: PropTypes.object,
  handleChangeSubmitedForm: PropTypes.func,
  numberOfCylesValues: PropTypes.array,
  isFormApproved: PropTypes.bool,
  isCreateNewPAF: PropTypes.bool,
  handleAditionalOptionsChange: PropTypes.func,
  aditionalOptions: PropTypes.object,
  typeOfPacks: PropTypes.array,
  aditionalOptionsState: PropTypes.object,
  setFormItems: PropTypes.func,
  dispensingForm: PropTypes.object,
};

export default ViewPAF;
